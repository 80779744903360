
export default (data = {}) => {
  return [
    {
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 511,
          h: 128,
          r: 5,
          lineColor: 'black',
        },
      ],
      absolutePosition: { x: 42, y: 170 },
    },
    {

      text: [`Transportista: ${data.proveedor ? data.proveedor.raz_soc : ''} (${data.id_proveed}) `, { text: 'Por cuenta y orden del CMCBA', fontSize: 8, bold: false }],
      color: '#000000',
      fontSize: 10,
      bold: true,
      absolutePosition: { x: 53, y: 178 },
    },
    {
      text: `Para: ${data.raz_soc}`,
      color: '#000000',
      fontSize: 10,
      bold: false,
      absolutePosition: { x: 53, y: 195 },
    },
    {
      text: `N° Pedido: ${data.id_pedido}   (Orden de entrega: ${data.idemcmc})`,
      color: '#000000',
      fontSize: 10,
      bold: false,
      absolutePosition: { x: 53, y: 213 },
    },

    {
      text: `Lugar de entrega: ${data.id_destino} - ${data.lugar_entrega}`,
      color: '#000000',
      fontSize: 10,
      bold: false,
      absolutePosition: { x: 53, y: 230 },
    },
    {
      text: `Calle: ${data.calle}`,
      color: '#000000',
      fontSize: 10,
      bold: false,
      absolutePosition: { x: 53, y: 247 },
    },
    {
      text: `Localidad: ${data.localidad}`,
      color: '#000000',
      fontSize: 10,
      bold: false,
      absolutePosition: { x: 284, y: 247 },
    },
    {
      text: `Partido: ${data.partido}`,
      color: '#000000',
      fontSize: 10,
      bold: false,
      absolutePosition: { x: 53, y: 265 },
    },
    {
      text: `Provincia: ${data.provincia}`,
      color: '#000000',
      fontSize: 10,
      bold: false,
      absolutePosition: { x: 284, y: 265 },
    },
    {
      text: `FECHA ESTIMADA DE ENTREGA: ${data.fecha_estimada_entrega}`,
      color: '#000000',
      fontSize: 10,
      bold: false,
      absolutePosition: { x: 53, y: 282 },
    },
    {
      qr: data.id_control, absolutePosition: { x: 480, y: 228 }, fit: 83,
    },
  ]
}
