import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from './fonts/vfs_fonts'

import { saveAs } from 'file-saver'

import generatePdf from './templates/remito'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  Inter: {
    normal: 'Inter-Regular.ttf',
    bold: 'Inter-SemiBold.ttf',
  },
}
class RemitoController {
  getRemito (data) {
    const docDefinition = generatePdf(data)

    return pdfMake.createPdf(docDefinition)
  }

  async dowloadRemito (data) {
    const remito = await this.getRemito(data)
    remito.getBlob((blob) => {
      saveAs(blob, `remito_${data.id_control}.pdf`)
    })
  }
}

export const remitoController = new RemitoController()
