
export default (data = {}) => {
  return [
    {
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 511,
          h: 474,
          r: 5,
          lineColor: 'black',
        },
      ],
      absolutePosition: { x: 42, y: 302 },
    },
    {
      canvas: [
          {
            type: 'line',
            x1: 0,
            x2: 510,
            y1: 0,
            y2: 0,
            lineWidth: 1,
            lineColor: 'black',
          },
        ],
        absolutePosition: { x: 43, y: 316 },
    },
    {
      canvas: [
          {
            type: 'line',
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 474,
            lineWidth: 1,
            lineColor: 'black',
          },
        ],
        absolutePosition: { x: 121, y: 302 },
    },
    {
      text: 'Cantidad',
      color: '#000000',
      fontSize: 9,
      bold: false,
      absolutePosition: { x: 63, y: 304 },
    },
    {
      text: 'Detalle',
      color: '#000000',
      fontSize: 9,
      bold: false,
      absolutePosition: { x: 320, y: 304 },
    },
  ]
}
