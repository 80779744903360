import dot from '../../images/dot'

export default (data = {}, dataFooter = {}) => {
  const response = []
  response.push({
    text: 'Recibí conforme:',
    color: '#000000',
    fontSize: 11,
    bold: true,
    absolutePosition: { x: 45, y: 16 },
  })

  let x = 467
  for (let index = 1; index <= dataFooter.copy; index++) {
    response.push({
      image: dot,
      width: 7,
      absolutePosition: { x, y: 0 },
    })

   x += 10
  }
  return response
}
