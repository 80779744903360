
export default (data = { detalles: [] }) => {
  const items = []

  data.detalles.forEach(e => {
    items.push({
      layout: 'noBorders',
      table: {
        headerRows: 0,
        widths: [69, 423],

        body: [

          [{
            text: e.cantdesp,
            color: '#000000',
            fontSize: 9,
            bold: false,
            margin: [0, 0, 6, 3],
            alignment: 'right',
          }, {
            text: e.descrip,
            color: '#000000',
            fontSize: 9,
            bold: false,
            margin: [6, 0, 0, 3],
            alignment: 'left',
          }],
        ],
      },
    })
  })
  return items
}
