
import topLeft from './blocks/topLeft'
import topRight from './blocks/topRight'
import datosEntrega from './blocks/datosEntrega'
import detalles from './blocks/detalles'
import footer from './blocks/footer'
import copySection from './blocks/copySection'
import itemDetalle from './blocks/itemDetalle'

const COPIES = 5

const generatePdf = (data = {}) => {
  const content = []
  let page = 0
  let copy = 1

  for (let index = 1; index <= COPIES; index++) {
    content.push(...itemDetalle(data))
    content.push(
      {
        text: '',
        pageBreak: index < COPIES ? 'after' : false,
      },
    )
  }

  const docDefinition = {
    pageSize: 'A4',

    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: 'portrait',

    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [42, 320, 0, 60],
    header: function (currentPage, pageCount, pageSize) {
      return [
        {
          text: 'DOCUMENTO NO VÁLIDO COMO FACTURA',
          color: '#000000',
          fontSize: 10,
          bold: true,
          absolutePosition: { x: 194, y: 25 },
        },
        ...topLeft(data),
        ...topRight(data),
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 26,
              h: 26,
              r: 0,
              lineColor: 'black',
              color: '#ffffff',
            },
          ],
          absolutePosition: { x: 284, y: 47 },
        },
        {
          text: 'R',
          color: '#000000',
          fontSize: 26,
          bold: true,
          absolutePosition: { x: 288.5, y: 44.5 },
        },
        ...datosEntrega(data),
        ...detalles(data),
        ...copySection(data),
      ]
    },
    footer: function (currentPage, pageCount, pageSize) {
      if (page >= Math.ceil(pageCount / COPIES)) {
        page = 0
        copy += 1
      }
      page += 1

      return [
        ...footer({}, { copy }),
        {
          text: `${page} / ${Math.ceil(pageCount / COPIES)}`,
          color: '#000000',
          fontSize: 9,
          bold: false,
          margin: [0, 35, 10, 0],
          alignment: 'right',
        },
      ]
    },
    content,
    styles: {
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
    },
    defaultStyle: {
      columnGap: 20,
      font: 'Inter',
    },
  }

  return { ...docDefinition }
}
export default generatePdf
