
export default (data = {}) => {
  return [
    {
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 248,
          h: 124,
          r: 5,
          lineColor: 'black',
        },
      ],
      absolutePosition: { x: 304, y: 42 },
    },
    {
      text: 'REMITO',
      color: '#000000',
      width: '*',
      fontSize: 10,
      bold: true,
      absolutePosition: { x: 321, y: 50 },
    },
    {
      text: 'N° ',
      color: '#000000',
      fontSize: 12,
      bold: true,
      absolutePosition: { x: 321, y: 66 },
    },
    {
      text: data.numero_remito,
      color: '#000000',
      fontSize: 13,
      bold: false,
      absolutePosition: { x: 340, y: 65 },
    },
    {
      text: 'FECHA',
      color: '#000000',
      fontSize: 10,
      bold: true,
      absolutePosition: { x: 321, y: 92 },
    },
    {
      text: data.fecha_emision,
      color: '#000000',
      fontSize: 12,
      bold: false,
      absolutePosition: { x: 364, y: 91 },
    },

    {
      text: 'C.U.I.T.: 30-54665974-3',
      color: '#000000',
      fontSize: 7.5,
      bold: false,
      absolutePosition: { x: 311, y: 119 },
    },

    {
      text: 'ING. BRUTOS.: 30-54665974-3 EXENTO',
      color: '#000000',
      fontSize: 7.5,
      bold: false,
      absolutePosition: { x: 311, y: 129 },
    },
    {
      text: 'INIC. ACT.: 10-08-1967',
      color: '#000000',
      fontSize: 7.5,
      bold: false,
      absolutePosition: { x: 311, y: 138 },
    },
    {
      text: 'Exceptuado cumplimiento RG (Afip) 1415 - anexo 1 Inciso a)',
      color: '#000000',
      fontSize: 7.5,
      bold: false,
      absolutePosition: { x: 311, y: 147 },
    },

  ]
}
