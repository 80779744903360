import mainLogo from '../../images/logo'

export default (data = {}) => {
  return [
    {
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 248,
          h: 124,
          r: 5,
          lineColor: 'black',
        },
      ],
      absolutePosition: { x: 42, y: 42 },
    },
    {
      image: mainLogo,
      width: 202,
      absolutePosition: { x: 65, y: 53 },
    },
    {
      text: 'Aut. Ricchieri y Boulogne Sur Mer',
      color: '#000000',
      fontSize: 9,
      bold: true,
      absolutePosition: { x: 86, y: 109 },
    },
    {
        text: '(1771) Tapiales',
        color: '#000000',
        fontSize: 9,
        bold: true,
        absolutePosition: { x: 126, y: 119 },
      },
      {
        text: 'Provincia de Buenos Aires',
        color: '#000000',
        fontSize: 9,
        bold: true,
        absolutePosition: { x: 102, y: 129 },
      },
      {
        canvas: [
            {
              type: 'line',
              x1: 0,
              x2: 226,
              y1: 0,
              y2: 0,
              lineWidth: 1,
              lineColor: 'black',
            },
          ],
          absolutePosition: { x: 53, y: 145 },
      },
      {
        text: 'IVA EXENTO',
        color: '#000000',
        fontSize: 9,
        bold: true,
        absolutePosition: { x: 137, y: 151 },
      },
  ]
}
