import copy from '../../images/copy'

export default (data = {}) => {
  return [
    {
      image: copy,
      width: 10,
      absolutePosition: { x: 28, y: 340 },
    },
  ]
}
